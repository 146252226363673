import React from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  MenuItem,
  Menu,
  Toolbar,
  Avatar,
  ListItemIcon,
  Badge,
  Typography
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Row } from 'simple-flexbox';
import {
  Menu as MenuIcon,
  NotificationsActive,
  AccountCircle,
  ExitToApp,
  Notifications,
  AccountCircleTwoTone
} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import Logo from './LogoComponent';
import {
  DRAWER_WITH,
  PRIVATE_BASE_PATH,
  ROUTES_WITH_SEARCHBAR,
  ENABLE_NOTIFICATIONS
} from '../globals';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';
import { isLoggedIn } from '../../services/authService';
import GlobalDebouncedFilterComponent from './GlobalDebouncedFilterComponent';
import NotificationsList from './Notifications';

const materialStyles = (theme) => ({
  offlineHeader: {
    backgroundColor: colors.lightRed,
    color: colors.white
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WITH,
    width: `calc(100% - ${DRAWER_WITH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    ...media.xSmallScreen({
      marginLeft: 0,
      marginRight: 0
    })
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  hide: {
    display: 'none'
  },
  ...media.hideSmall,
  ...media.hideXSmall,
  ...media.hideLarge,
  secondText: {
    ...typography.subTitle,
    fontStyle: 'italic',
    ...media.xSmallScreen({
      fontSize: 20,
      lineHeight: '24px'
    })
  },
  profileAvatar: {
    width: 50,
    height: 50,
    marginRight: 10,
    ...media.xSmallScreen({
      width: 40,
      height: 40,
      marginRight: 0
    })
  },
  notificationsIcon: {
    fontSize: 30,
    ...media.xSmallScreen({
      fontSize: 25
    })
  }
});

class HeaderComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { openProfileMenu: null, searchValue: '', openNotifications: false };
  }

  handleMenuClose = () => this.setState({ openProfileMenu: null });

  redirectToProfile = () => {
    const { history } = this.props;
    this.handleMenuClose();
    history.push(`${PRIVATE_BASE_PATH}profile`);
  };

  redirectToLogout = () => {
    const { history } = this.props;
    this.handleMenuClose();
    history.push(`/logout`);
  };

  markAllRead = () => {
    const { user, markAllAsRead } = this.props;
    this.setState({ openNotifications: true });
    if (user.notifications && user.notifications.unreadCount) {
      return markAllAsRead({
        optimisticResponse: {
          markAllAsRead: {
            __typename: 'markAllAsReadPayload',
            notifications: {
              ...user.notifications,
              list: user.notifications.list.map((n) => {
                const cloned = { ...n };
                cloned.readAt = new Date();
                return cloned;
              }),
              unreadCount: 0,
              __typename: 'UserNotifications'
            }
          }
        }
      });
    }
  };

  getDesktopContent = () => {
    const { openProfileMenu, openNotifications } = this.state;
    const { classes, location, history, offline } = this.props;
    const user = this.props.user;
    // TODO ESTO NO ANDA COMO QUIERO
    const hasSearchBar = ROUTES_WITH_SEARCHBAR.some((route) => {
      const path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
      return path == `${PRIVATE_BASE_PATH}${route}`;
    });
    return (
      <Row horizontal="end" style={{ flex: 1 }}>
        {offline && (
          <Row vertical="center" horizontal="center" style={{ flex: 1 }}>
            <SignalWifiOffIcon style={{ color: colors.white, fontSize: 25, marginRight: 5 }} />
            ACTUALMENTE SIN CONEXIÓN
          </Row>
        )}
        {hasSearchBar && !offline && (
          <Toolbar disableGutters>
            <GlobalDebouncedFilterComponent />
          </Toolbar>
        )}
        <Toolbar>
          {user &&
            user.notifications &&
            user.notifications.list.length > 0 &&
            ENABLE_NOTIFICATIONS && (
              <IconButton color="inherit" style={{ marginRight: 20 }} onClick={this.markAllRead}>
                <Badge badgeContent={user && user.notifications.unreadCount} color="error">
                  {user && user.notifications.unreadCount ? (
                    <NotificationsActive className={classes.notificationsIcon} />
                  ) : (
                    <Notifications className={classes.notificationsIcon} />
                  )}
                </Badge>
              </IconButton>
            )}
          {/* profile menu begins */}
          <Row
            vertical="center"
            onClick={(event) => this.setState({ openProfileMenu: event.currentTarget })}
          >
            {user ? (
              <React.Fragment>
                {user.profilePhotoUrl ? (
                  <Avatar alt="pic" src={user.profilePhotoUrl} className={classes.profileAvatar} />
                ) : (
                  <AccountCircleTwoTone className={classes.profileAvatar} />
                )}
                <Typography className={classes.hideXSmall} variant="subtitle1">
                  {`Hola ${user.firstName || ''}!`}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Skeleton variant="circle" className={classes.profileAvatar} />
                {!media.isXSmallScreen() && <Skeleton variant="text" width={100} />}
              </React.Fragment>
            )}
          </Row>
          <Menu
            disableAutoFocusItem
            id="menu-appbar"
            anchorEl={openProfileMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={!!openProfileMenu}
            onClose={this.handleMenuClose}
          >
            <MenuItem className={classes.menuItem} onClick={this.redirectToProfile}>
              <ListItemIcon className={classes.icon}>
                <AccountCircle />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={this.redirectToLogout} id="logout">
              <ListItemIcon className={classes.icon}>
                <ExitToApp />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          {/* profile menu ends */}
          <NotificationsList
            open={openNotifications}
            onClose={() => this.setState({ openNotifications: null })}
            history={history}
          />
        </Toolbar>
      </Row>
    );
  };

  getTitleComponent = () => {
    const { classes, offline } = this.props;
    return (
      <div className={isLoggedIn() ? classes.hideSmall : null}>
        <Row vertical="center" style={{ flex: 1 }}>
          <Logo alt={offline} />
        </Row>
      </div>
    );
  };

  render() {
    const { classes, openDrawer, handleDrawerOpen, simpleHeader, offline } = this.props;
    return (
      <AppBar
        color={'secondary'}
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: openDrawer,
          [classes.offlineHeader]: offline
        })}
      >
        <Toolbar disableGutters={!openDrawer}>
          {isLoggedIn() && !simpleHeader && (
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: openDrawer
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          {this.getTitleComponent()}
          {isLoggedIn() && !simpleHeader && this.getDesktopContent()}
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderComponent.propTypes = {
  classes: object,
  openDrawer: bool,
  offline: bool,
  handleDrawerOpen: func,
  user: object,
  history: object,
  location: object,
  simpleHeader: bool,
  markAllAsRead: func
};

export default withRouter(withStyles(materialStyles, { withTheme: true })(HeaderComponent));
