import decode from 'unescape';
import Quagga from 'quagga';
import * as localStorageService from '../services/localStorageService';
import { browserHistory } from './globals';

export function setUrlToRedirectAfterLogin(url) {
  localStorageService.setItem('redirectToAfterLogin', url);
}

export function getUrlToRedirectAfterLogin() {
  return localStorageService.getItem('redirectToAfterLogin');
}

export function setCurrentUrlToRedirectAfterLogin() {
  if (!['/login', '/error'].some((r) => browserHistory.location.pathname.indexOf(r) !== -1)) {
    const url = browserHistory.location.pathname + location.search;
    setUrlToRedirectAfterLogin(url);
  }
}

export const createMarkup = (htmlString) => ({ __html: decode(htmlString) });

export const isDark = (theme) => theme.palette.type == 'dark';

export const decodeImgAsync = async (src, readers) => {
  return new Promise((resolve, reject) => {
    Quagga.decodeSingle(
      {
        src,
        numOfWorkers: 0, // Needs to be 0 when used within node
        inputStream: {
          size: 800 // restrict input-size to be 800px in width (long-side)
        },
        decoder: {
          readers // List of active readers
        },
        debug: {
          drawBoundingBox: true,
          drawScanline: true,
          showPattern: true
        },
        locator: {
          halfSample: true,
          patchSize: 'xlarge'
        }
      },
      function (result) {
        if (result && result.codeResult) {
          console.log(`ESCANEADO: ${result.codeResult.code} -  ${result.codeResult.format}`);
          resolve(result.codeResult.code);
        } else {
          reject('not detected');
        }
      }
    );
  });
};

export const decodeImgBar = async (src, readers) => {
  try {
    const defaultReaders = ['ean_reader', 'ean_8_reader'];
    const result = await decodeImgAsync(src, readers || defaultReaders);
    return result;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const isUserMediaSupported = () =>
  navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';

export const sanitizeEnumValue = (value, defaultString) => {
  if (!value) return defaultString || null;
  return value.replace(/_/g, ' ');
};

export const forceDownloadFile = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getFullCityString = (city) =>
  city ? `${city.name}, ${!city.zipcode ? city.zipcode : ''} ${city.state}` : '';

export const encode = (type, id) => Buffer.from(`${type}:${id || ''}`).toString('base64');

// Detects if device is in standalone mode
export const isInStandaloneMode = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  if (document.referrer.startsWith('android-app://') || navigator.standalone || isStandalone) {
    return true;
  }

  return false;
};
