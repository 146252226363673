import { alpha } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import * as media from '../../styles/media-queries';
import { isDark } from '../../utilities';

export const textFieldStyles = (theme) => ({
  textField: {
    minHeight: 77,
    ...media.tillLargeScreen({
      minWidth: 0
    })
  },
  selectField: {
    minHeight: 77,
    ...media.tillLargeScreen({
      minWidth: 0
    })
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: 22,
    color: isDark(theme) ? null : alpha(colors.black, 0.64),
    fontWeight: 'bold',
    ...media.tillLargeScreen({
      fontSize: 20
    })
  },
  switchLabel: {
    fontSize: 16,
    color: isDark(theme) ? null : alpha(colors.black, 0.64),
    fontWeight: 'bold',
    ...media.tillLargeScreen({
      fontSize: 15
    })
  }
});
