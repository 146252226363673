import React from 'react';
import { object, bool, func } from 'prop-types';
import classNames from 'classnames';
import { Row } from 'simple-flexbox';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  ExitToApp,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Assessment,
  PersonAdd,
  ShowChart
} from '@material-ui/icons';
import { DRAWER_WITH, PRIVATE_BASE_PATH } from '../globals';
import * as media from '../styles/media-queries';
import Logo from './LogoComponent';
import { useIsAdmin } from '../../hooks/useIsAdmin';

const styles = (theme) => ({
  drawer: {
    width: DRAWER_WITH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: DRAWER_WITH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  paper: {
    background: theme.palette.primary.main
  },
  ...media.hideLarge,
  closeMenuIcon: {
    ...media.xSmallScreen({
      flex: 1,
      justifyContent: 'flex-end'
    })
  },
  icon: {
    fontSize: 40
  },
  iconContainer: {
    marginRight: 5,
    ...media.xSmallScreen({
      marginRight: 0,
      minWidth: 50
    })
  }
});

const SideBar = ({ classes, theme, handleDrawerClose, openDrawer }) => {
  // const isItemSelected = (itemName) => {
  //   const { pathname } = this.props.location;
  //   return pathname.indexOf(itemName) !== -1;
  // };

  const isAdmin = useIsAdmin();

  return (
    <Drawer
      variant={media.isSmallScreen() ? 'temporary' : 'permanent'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: openDrawer,
        [classes.drawerClose]: !openDrawer
      })}
      classes={{
        paper: classNames(classes.paper, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })
      }}
      open={openDrawer}
      onClose={handleDrawerClose}
    >
      <Row className={classes.toolbar}>
        <Logo alt className={classes.hideLarge} style={{ flex: 1 }} />
        <IconButton onClick={handleDrawerClose} className={classes.closeMenuIcon}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Row>
      <Divider />
      <List style={{ marginTop: 30 }}>
        <Tooltip title="DASHBOARD" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}dashboard`} style={{ textDecoration: 'none' }}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <DashboardIcon color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Dashboard'}
                primaryTypographyProps={{ style: { color: 'white' } }}
              />
            </ListItem>
          </Link>
        </Tooltip>
        <Tooltip title="LICITACIONES" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}tenders/index/1`} style={{ textDecoration: 'none' }}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <Assessment color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Licitaciones'}
                primaryTypographyProps={{ style: { color: 'white' } }}
              />
            </ListItem>
          </Link>
        </Tooltip>
        {isAdmin && (
          <Tooltip title="COMISIONES" placement="right">
            <Link to={`${PRIVATE_BASE_PATH}commissionsreport`} style={{ textDecoration: 'none' }}>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <ShowChart color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Comisiones'}
                  primaryTypographyProps={{ style: { color: 'white' } }}
                />
              </ListItem>
            </Link>
          </Tooltip>
        )}
        {isAdmin && (
          <Tooltip title="USUARIOS" placement="right">
            <Link to={`${PRIVATE_BASE_PATH}users`} style={{ textDecoration: 'none' }}>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <PersonAdd color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Usuarios'}
                  primaryTypographyProps={{ style: { color: 'white' } }}
                />
              </ListItem>
            </Link>
          </Tooltip>
        )}
      </List>
      <Divider />
      <Tooltip title="CONFIGURACIÓN" placement="right">
        <Link to={`${PRIVATE_BASE_PATH}settings`} style={{ textDecoration: 'none' }}>
          <ListItem id="sidebar-settings-menu" button onClick={handleDrawerClose}>
            <ListItemIcon
              className={classNames({
                [classes.iconContainer]: openDrawer
              })}
            >
              <SettingsIcon color="secondary" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={'Configuración'}
              primaryTypographyProps={{ style: { color: 'white' } }}
            />
          </ListItem>
        </Link>
      </Tooltip>
      <Tooltip title="CERRAR SESIÓN" placement="right">
        <Link to={`/logout`} style={{ textDecoration: 'none' }}>
          <ListItem button onClick={handleDrawerClose}>
            <ListItemIcon
              className={classNames({
                [classes.iconContainer]: openDrawer
              })}
            >
              <ExitToApp color="secondary" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={'Cerrar Sessión'}
              primaryTypographyProps={{ style: { color: 'white' } }}
            />
          </ListItem>
        </Link>
      </Tooltip>
    </Drawer>
  );
};

SideBar.propTypes = {
  location: object,
  classes: object,
  openDrawer: bool,
  handleDrawerClose: func,
  theme: object,
  history: object
};

export default withRouter(withStyles(styles, { withTheme: true })(SideBar));
