import { useEffect } from 'react';
import { logout } from '../../services/authService';

const LogoutComponent = () => {
  useEffect(() => {
    logout(false);
  }, []);

  return null;
};

export default LogoutComponent;
