import React from 'react';
import { object } from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import NotFoundImg from '../../../public/notfound_inv.png';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';

const styles = () => ({
  container: {
    margin: '0 auto',
    maxWidth: 624,
    textAlign: 'center',
    paddingTop: 8
  },
  title: {
    margin: '4px 0px 16px 0px',
    fontFamily: 'Century Gothic',
    textAlign: 'center',
    color: colors.darkBlueGrey,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '36px',
    ...media.xSmallScreen({
      fontSize: 20,
      lineHeight: '30px'
    })
  },
  description: {
    color: colors.darkBlueGrey,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: 24,
    ...media.xSmallScreen({
      fontSize: 16,
      lineHeight: '24px'
    })
  }
});

const PageNotFound = ({ classes }) => {
  return (
    <div className={classes.container}>
      <img
        src={NotFoundImg}
        style={{
          width: 300,
          height: 300
        }}
      />
      <p className={classes.title}>La página que buscas no existe</p>
      <Button
        variant="contained"
        onClick={() => {
          window.location = `/`;
        }}
      >
        Ir al Inicio
      </Button>
    </div>
  );
};

PageNotFound.propTypes = {
  classes: object
};

export default withStyles(styles, { whitTheme: true })(PageNotFound);
