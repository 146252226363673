export const lightGray = '#CECECE';
export const grayDark = '#A2A5A4';
export const gray2 = '#183956';
export const gray5 = '#F0F5F8';
export const gray6 = '#667D99';
export const gray7 = '#050615';
export const red = '#B10000';
export const lightRed = '#db4835';
export const orange = '#C5782B';
export const lightOrange = '#f6e6d5';
export const hoveredLink = '#1c5a84';
export const white = '#FFFFFF';
export const black = '#21282A';
export const black1 = '#212121';
export const darkBlueGrey = '#333F4D';
export const grey = '#7A7D80';
export const lightBlueGrey = '#ACB9C8';
export const link = '#2979FF';
export const green = '#268E36';
export const green2 = '#296932';
export const lightBrightGreen = '#90EE90';
export const lightGreen = '#9CCBA3';
export const pink = '#E91E63';
export const yellow = '#f2ef1d';
export const goldenLight = '#d4c94c';
export const golden = '#a09916';
export const purple = '#9000c6';
export const whatsappGreen = '#128C7E';

export const blackTransparent = 'rgba(0, 0, 0, 0.870588)';
export const transparent = 'rgba(0, 0, 0, 0)';

export const lightThemePalette = {
  primary: {
    main: hoveredLink,
    light: link,
    dark: gray2
  },
  secondary: {
    main: gray5,
    light: white,
    dark: lightGray
  }
};

export const darkThemePalette = {
  primary: {
    main: '#212121',
    light: '#616161',
    dark: '#424242'
  },
  secondary: {
    light: '#388e3c',
    main: '#33691e',
    dark: '#2e7d32'
  }
};

export const error = red;
