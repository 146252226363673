import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import shortLogo from '../../../public/shortlogo.png';
// import logo from '../../../public/newlogo_med.png';
import logoSm from '../../../public/newlogo_sm.png';
import logoAlt from '../../../public/newlogo_inv_sm.png';
import * as media from '../styles/media-queries';

const styles = () => ({
  logo: {
    maxWidth: 165,
    position: 'relative',
    top: 2,
    ...media.xSmallScreen({
      maxWidth: 130
    }),
    ...media.print({
      width: 120,
      maxWidth: 120
    })
  },
  shortLogoPrint: {
    maxWidth: 95,
    ...media.print({
      maxWidth: 60
    })
  }
});

const Logo = ({
  externalLink,
  logoLink,
  className,
  classNameImg,
  style,
  styleImg,
  shortVersion,
  alt,
  theme,
  classes
}) => {
  const getLogoImg = () => {
    if (theme.palette.type === 'dark') return logoAlt;
    if (shortVersion) {
      return shortLogo;
    } else if (alt) {
      return logoAlt;
    }
    return logoSm;
  };
  const Img = (
    <img
      alt="Logo"
      src={getLogoImg()}
      // srcSet={`${shortVersion ? shortLogo : logoSm} 450w`}
      className={classnames(classes.logo, classNameImg, { [classes.shortLogoPrint]: shortVersion })}
      style={{ ...styleImg }}
    />
  );
  return (
    <div className={classnames(className)} style={{ ...style }}>
      {externalLink !== undefined ? (
        <a href={externalLink} target="_blank" rel="noreferrer noopener">
          {Img}
        </a>
      ) : (
        <Link to={logoLink}>{Img}</Link>
      )}
    </div>
  );
};

Logo.propTypes = {
  externalLink: PropTypes.string,
  logoLink: PropTypes.string,
  className: PropTypes.string,
  classNameImg: PropTypes.string,
  style: PropTypes.object,
  styleImg: PropTypes.object,
  shortVersion: PropTypes.bool,
  alt: PropTypes.bool,
  classes: PropTypes.object,
  theme: PropTypes.object
};

Logo.defaultProps = {
  logoLink: '/'
};

export default withStyles(styles, { withTheme: true })(Logo);
