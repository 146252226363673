import React from 'react';
import { object, string, func, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import classnames from 'classnames';
import { isDark } from '../utilities';
import { white } from '../styles/colors';

const styles = (theme) => ({
  search: {
    'position': 'relative',
    'borderRadius': theme.shape.borderRadius,
    'borderColor': isDark(theme) ? white : null,
    'border': isDark(theme) ? '1px solid' : null,
    'backgroundColor': alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25)
    },
    'marginRight': theme.spacing(2),
    'marginLeft': 0,
    'width': '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  }
});

const SearchBar = ({ classes, value, onChange, className, id, autoFocus, style }) => {
  return (
    <div className={classnames(classes.search, className)} style={style}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        autoFocus={!!autoFocus}
        id={id}
        placeholder="Buscar..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

SearchBar.propTypes = {
  classes: object,
  style: object,
  className: string,
  value: string,
  id: string,
  onChange: func,
  autoFocus: bool
};

export default withStyles(styles)(SearchBar);
