import { setItem } from '../services/localStorageService';
import { APOLLO_CACHE_KEY } from '../utils/globals';
import userProfileQuery from '../graphql/queries/userProfile.graphql';
import { logout, getCurrentToken } from '../services/authService';
import { updateUserProfileAction } from '../redux/store-actions';
import store, { persistor } from '../redux/store';

export const getAuthorizationString = () => {
  const token = getCurrentToken();
  let authToken = '';
  if (token) {
    authToken += `Bearer ${token}`;
  }
  return authToken;
};

/**
 * Query the user profile and update the Redux store with it.
 * Used for example when the user refresh the page in the browser and the store it's flushed.
 */
export const queryAndUpdateUserData = async (fetchPolicy = '') => {
  try {
    const { apolloClient } = store.getState();
    if (!apolloClient) return Promise.resolve();

    const { data } = await apolloClient.query({ query: userProfileQuery, fetchPolicy });

    const user = data?.viewer?.userProfile || {};

    if (!data || !user || user == {}) {
      return logout();
    }

    store.dispatch(updateUserProfileAction(user));
    return user;
  } catch (error) {
    console.log(error);
    if (error.message === 'Network error: Failed to fetch') {
      return;
    }
    return logout();
  }
};

export const clearLocalCache = async (client = null) => {
  const { apolloClient } = store.getState();
  if (!client && !apolloClient) return Promise.reject('not client available');
  console.log('===>>> clearing local data');
  await (client || apolloClient).clearStore();
  // purge redux LS data
  persistor.purge();
  setItem(APOLLO_CACHE_KEY, null);
};

export const clearAndRefreshLocalCache = async (client = null) => {
  await clearLocalCache(client);
  await queryAndUpdateUserData('network-only');
  setItem(APOLLO_CACHE_KEY, null);
};

// TODO THIS IS NOT WORKING OK
const fetchOfflineQueriesData = async () => {
  const { apolloClient } = store.getState();
  if (!apolloClient) return Promise.resolve();

  // await apolloClient.query({
  //   query: dashboardDataQuery,
  //   variables: { opesi: false }
  // });

  console.log('fetched offline data');
};

// TODO NOT WORKING ... it's in the cache but the query does not resolve anyway.
export const fetchAndSyncOfflineData = async () => {
  console.log('fetching offline data');
  // return new Promise((resolve, reject) => {
  try {
    await clearAndRefreshLocalCache();
    await fetchOfflineQueriesData();
    return Promise.resolve();
  } catch (e) {
    Promise.reject(e);
  }
  // });
};

export const removeFromApolloCache = (keys, cache) => {
  const graphqlCacheKeysPatterns = {
    tenders: /TendersList/,
    uocs: /Uoc/
  };

  const arrayOfKeys = Array.isArray(keys) ? keys : [keys];

  const patterns = arrayOfKeys.reduce((acc, next) => {
    const p = graphqlCacheKeysPatterns[next];
    if (!p) {
      console.log(`No pattern for key: ${next}`);
      return acc;
    }
    acc.push(p);
    return acc;
  }, []);

  if (patterns.length === 0) {
    console.log('No patterns found for: ', keys);
    return;
  }

  Object.keys(cache.data.data).forEach((key) => {
    if (patterns.some((p) => key.match(p))) {
      cache.data.delete(key);
    }
  });
};

export const ApolloNetworkStatus = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLLING: 6,
  READY: 7,
  ERROR: 8
};
