import React, { useEffect, useState } from 'react';
import { ApolloProvider, useApolloClient, ApolloClient, ApolloLink } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { InMemoryCache } from '@apollo/client/cache';
import loggerLink from 'apollo-link-logger';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// import { Button } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import queryString from 'query-string';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import {
  browserHistory,
  APOLLO_CACHE_KEY,
  ENABLE_OFFLINE_MODE,
  ROUTES_SPECIAL_AUTH
} from './utils/globals';
import store, { persistor } from './redux/store';
import {
  errorLink,
  // queueLink,
  // serializingLink,
  retryLink,
  httpLink,
  trackerLink,
  authLink
} from './apollo/links';
import { setApolloClientAction, setOfflineMode, removeTrackedQuery } from './redux/store-actions';
import ToastNotificationEmitter from './utils/common-components/ToastNotificationEmitter';
import { Loading } from './utils/common-components/UIBlocker';
import AppLayout from './AppLayout';
import { updateFunctions } from './graphql/update-functions';
import { useDarkMode } from './utils/styles/useDarkMode';
import { tryLoginFromLocalStorage, alternateLogin } from './services/authService';
import GlobalCssPriority from './utils/styles/GlobalCssPriority';

const cache = new InMemoryCache({
  // TODO: Esto no haría falta, pero aunq las listas tienen ID me sigue tirando
  // warnings al pisar la cache con apollo 3
  typePolicies: {
    // Viewer: {
    //   fields: {
    //     usersList: {
    //       merge(existing, incoming) {
    //         // Equivalent to what happens if there is no custom merge function.
    //         return incoming;
    //       }
    //     }
    //   }
    // }
  }
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    loggerLink,
    errorLink,
    trackerLink,
    // queueLink,
    // serializingLink,
    retryLink,
    authLink,
    httpLink
  ]),
  resolvers: []
});

store.dispatch(setApolloClientAction(client));

const AppUsingReduxUsingApollo = () => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const online = useSelector((state) => !state.offline);
  const trackedQueries = useSelector((state) => state.trackedQueries);
  const themeMode = useSelector((state) => state.theme);
  const [trackedLoaded, setTrackedLoaded] = useState(false);
  // const [onlineQueryFailed, setOnlineQueryFailed] = useState(false);

  // TRACKED QUERIES
  useEffect(() => {
    const reApplyOfflineMutations = async () => {
      const promises = [];
      if (ENABLE_OFFLINE_MODE) {
        trackedQueries.forEach((trackedQuery) => {
          const context = JSON.parse(trackedQuery.contextJSON);
          const query = JSON.parse(trackedQuery.queryJSON);
          const variables = JSON.parse(trackedQuery.variablesJSON);
          promises.push(
            apolloClient.mutate({
              context,
              mutation: query,
              optimisticResponse: context.optimisticResponse,
              update: updateFunctions[trackedQuery.name],
              variables
            })
          );
          dispatch(removeTrackedQuery(trackedQuery.id));
        });
      }
      if (online) {
        try {
          await Promise.all(promises);
        } catch (e) {
          // ALLOW TRACKED QUERIES TO FAIL
        }
      }
      setTrackedLoaded(true);
    };

    reApplyOfflineMutations();
  }, []);
  const { theme, getTheme, componentMounted } = useDarkMode();
  const [currentTheme, setCurrentTheme] = useState(theme);

  useEffect(() => {
    setCurrentTheme(getTheme(themeMode));
  }, [themeMode]);

  if (!trackedLoaded || !componentMounted) {
    return <Loading loading />;
  }
  // if (onlineQueryFailed) {
  //   return <Text>Error Online Query</Text>;
  // }
  const notistackRef = React.createRef();
  // const onClickDismiss = (key) => () => {
  //   notistackRef.current.closeSnackbar(key);
  // };

  return (
    <DndProvider backend={HTML5Backend}>
      <MuiThemeProvider theme={currentTheme}>
        <GlobalCssPriority>
          <Router history={browserHistory}>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={1}
              dense
              preventDuplicate
              // action={(key) => {
              //   return (
              //     <Button onClick={() => notistackRef.current.closeSnackbar(key)} size="small">
              //       CERRAR
              //     </Button>
              //   );
              // }}
            >
              <React.Fragment>
                <ToastNotificationEmitter />
                <AppLayout store={store} />
              </React.Fragment>
            </SnackbarProvider>
          </Router>
        </GlobalCssPriority>
      </MuiThemeProvider>
    </DndProvider>
  );
};

const AppUsingRedux = () => {
  const dispatch = useDispatch();
  const online = useSelector((state) => !state.offline);
  const [onlineChecked, setOnlineChecked] = useState(false);
  const [cachePersisted, setCachePersisted] = useState(false);

  // OFFLINE
  useEffect(() => {
    const execute = async () => {
      try {
        dispatch(setOfflineMode(!navigator.onLine));
        setOnlineChecked(true);
      } catch (err) {
        //
      }
    };
    execute();
    window.addEventListener('offline', () => {
      // navigator.onLine
      store.dispatch(setOfflineMode(true));
    });

    window.addEventListener('online', () => {
      // TODO REFETCH QUERIES NETWORK ONLY??
      store.dispatch(setOfflineMode(false));
    });
  }, []);

  // APOLLO CLIENT PERSIST
  useEffect(() => {
    const execute = async () => {
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        key: APOLLO_CACHE_KEY
      });
      setCachePersisted(true);
    };
    execute();
  }, []);

  // APOLLO CLIENT QUEUE
  useEffect(() => {
    if (ENABLE_OFFLINE_MODE) {
      if (online) {
        console.log('HOOK ONLINE OPEN');
        // queueLink.open();
      } else {
        console.log('HOOK ONLINE CLOSED');
        // queueLink.close();
      }
    } else {
      console.log('OFFLINE OPERATION DISABLED');
    }
  }, [online]);

  if (!cachePersisted || !onlineChecked) {
    return <Loading loading />;
  }

  if (ROUTES_SPECIAL_AUTH.some((r) => window.location.pathname.indexOf(r) !== -1)) {
    const qs = queryString.parse(window.location.search);
    if (qs.token) {
      alternateLogin(qs.token);
    }
  }

  tryLoginFromLocalStorage();
  return (
    <ApolloProvider client={client}>
      <AppUsingReduxUsingApollo />
    </ApolloProvider>
  );
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading loading />} persistor={persistor}>
      <AppUsingRedux />
    </PersistGate>
  </Provider>
);

export default App;
