import EventEmitter from 'events';
import { useEffect } from 'react';
import { func } from 'prop-types';
import { withSnackbar } from 'notistack';

const Emitter = new EventEmitter();

const ToastContextEmitter = (props) => {
  const anchorOptions = {
    vertical: 'top',
    horizontal: 'center'
  };

  useEffect(() => {
    Emitter.addListener('status_message', ({ message, type }) => {
      if (type == 'info') {
        props.enqueueSnackbar(message, {
          variant: 'info',
          anchorOrigin: anchorOptions
        });
      } else if (type == 'error') {
        props.enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: anchorOptions
        });
      } else {
        props.enqueueSnackbar(message, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });
      }
    });
  }, []);

  return '';
};

ToastContextEmitter.propTypes = {
  enqueueSnackbar: func
};

export const showInfoMessage = (message) => {
  Emitter.emit('status_message', {
    message,
    type: 'info'
  });
};

export const showErrorMessage = (message) => {
  Emitter.emit('status_message', {
    message,
    type: 'error'
  });
};

export const showNotification = (message) => {
  Emitter.emit('status_message', {
    message,
    type: 'notification'
  });
};

export default withSnackbar(ToastContextEmitter);
