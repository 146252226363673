import React, { useState } from 'react';
import {
  Drawer,
  Typography,
  Divider,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  List
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { any, func, object } from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { AccessAlarms, Clear, DoneAll, PostAdd } from '@material-ui/icons';
import { Row, Column } from 'simple-flexbox';
import { getFormattedDateLocalTZ } from '@eeff/shared';
import * as colors from '../styles/colors';
import { createMarkup } from '../utilities';
// import { PRIVATE_BASE_PATH } from '../globals';
import dismissNotificationGQL from '../../graphql/mutations/notifications/dismissNotification.graphql';
import viewAllNotificationsGQL from '../../graphql/mutations/notifications/viewAllNotifications.graphql';
import markAsViewedNotificationGQL from '../../graphql/mutations/notifications/markAsViewedNotification.graphql';
import { updateUserProfileAction } from '../../redux/store-actions';
import ConfirmationDialog from './ConfirmationDialog';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import { showNotification } from './ToastNotificationEmitter';
import { EnumNotificationType } from '../../enums/Enums';
import classes from './Notifications.module.scss';

const Notifications = ({ open, onClose }) => {
  const [dismissNotificationMut] = useMutation(dismissNotificationGQL);
  const [markAllAsViewedMut] = useMutation(viewAllNotificationsGQL);
  const [markAsViewedNotificationMut] = useMutation(markAsViewedNotificationGQL);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfileData) || {};
  const [changelog, setChangelog] = useState(false);
  const userNotifications = (user && user.notifications) || {};
  const notifications = userNotifications.list || [];

  // useEffect(() => {
  //   if (userNotifications.unreadCount && !open) {
  //     notifications
  //       .filter((n) => !n.readAt)
  //       .slice(0, 3)
  //       .forEach((n) => {
  //         const m = (
  //           <Column>
  //             <Row vertical="center">
  //               <Typography variant="h6" className={classes.notifTitle}>
  //                 {n.title}
  //               </Typography>
  //               <Typography
  //                 variant="h6"
  //                 style={{ color: colors.darkBlueGrey, marginLeft: 4, fontSize: 12 }}
  //               >
  //                 - {getFormattedDateLocalTZ(n.createdAt)}
  //               </Typography>
  //             </Row>
  //             <Typography variant="subtitle1" style={{ fontSize: 13 }}>
  //               <div dangerouslySetInnerHTML={createMarkup(n.content)} />
  //             </Typography>
  //           </Column>
  //         );
  //         showNotification(m);
  //       });
  //   }
  // }, [userNotifications.unreadCount]);

  const handleClick = (notif) => {
    // const metadata = (notif.metadata && JSON.parse(notif.metadata)) || null;

    markAsViewedNotificationMut({
      variables: { id: notif.id }
    });

    switch (notif.type) {
      // case EnumNotificationType.NEW_SELL:
      //   history.push(`${PRIVATE_BASE_PATH}sells/index/1/${metadata.sellId}`);
      //   break;

      case EnumNotificationType.CHANGELOG:
        setChangelog(notif);
        break;
    }
    return onClose();
  };

  const dismissNotif = (e, notif) => {
    // TO AVOID CALLING MARK AS VIEWED
    e.stopPropagation();
    e.preventDefault();
    const optimisticResult = {
      ...user.notifications,
      list: notifications.filter((n) => n.id !== notif.id),
      unreadCount: user.notifications.unreadCount - (notif.readAt ? 0 : 1),
      __typename: 'UserNotifications'
    };
    // TODO EN EL FUTUROO CREO Q LO MEJOR SERA PASAR EL PROFILE A UN WRAPPER COMO HACEMOOS EN TT
    dispatch(
      updateUserProfileAction({
        ...user,
        notifications: optimisticResult
      })
    );
    dismissNotificationMut({
      variables: { id: notif.id },
      optimisticResponse: {
        dismiss: {
          __typename: 'dismissPayload',
          notifications: {
            ...optimisticResult
          }
        }
      }
    });
  };

  const viewAll = async (e) => {
    // TO AVOID CALLING MARK AS VIEWED
    e.stopPropagation();
    e.preventDefault();
    const optimisticResult = {
      ...user.notifications,
      list: notifications.map((n) => {
        const cloned = { ...n };
        cloned.viewedAt = new Date();
        return cloned;
      }),
      unreadCount: 0,
      __typename: 'UserNotifications'
    };
    // TODO EN EL FUTUROO CREO Q LO MEJOR SERA PASAR EL PROFILE A UN WRAPPER COMO HACEMOOS EN TT
    dispatch(
      updateUserProfileAction({
        ...user,
        notifications: optimisticResult
      })
    );
    await markAllAsViewedMut({
      optimisticResponse: {
        markAllAsViewed: {
          __typename: 'markAllAsViewedPayload',
          notifications: {
            ...optimisticResult
          }
        }
      }
    });
  };

  const notifElement = (notif, isLast) => {
    let Icon;
    let content = createMarkup(notif.content);

    switch (notif.type) {
      case EnumNotificationType.RENEW_OFFER_DATE_ARRIVED:
        Icon = AccessAlarms;
        break;
      case EnumNotificationType.CHANGELOG:
        content = createMarkup('Haz click y descubre que ha cambiado');
        Icon = PostAdd;
        break;
    }

    return (
      <Column
        onClick={() => handleClick(notif)}
        key={notif.id}
        style={{ height: 'auto', marginBottom: 2 }}
      >
        <ListItem
          disableGutters
          className={classnames(classes.listItem, classes.notifRow, {
            [classes.unreadNotif]: !notif.viewedAt
          })}
        >
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            <Icon color="primary" className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Row vertical="center" style={{ height: 'auto' }}>
                <Column style={{ flex: 3 }} vertical="center">
                  <Row vertical="center">
                    <Typography variant="h6" className={classes.notifTitle}>
                      {notif.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: colors.darkBlueGrey, marginLeft: 4, fontSize: 12 }}
                    >
                      - {getFormattedDateLocalTZ(notif.createdAt)}
                    </Typography>
                  </Row>
                  <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                    <div dangerouslySetInnerHTML={content} />
                  </Typography>
                </Column>
                <Clear
                  color="primary"
                  className={classes.clearIcon}
                  onClick={(e) => dismissNotif(e, notif)}
                />
              </Row>
            }
          />
        </ListItem>
        {!isLast && <Divider style={{ border: `1px solid ${colors.darkBlueGrey}` }} />}
      </Column>
    );
  };

  return (
    <React.Fragment>
      <Drawer
        variant={'temporary'}
        anchor="right"
        className={classnames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: classnames(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={!!open && notifications.length > 0}
        onClose={onClose}
      >
        <List>
          <ListItem disableGutters style={{ padding: 0, height: 35 }}>
            <Row horizontal="end" className={classes.headerRow}>
              <Button size="small" onClick={viewAll}>
                <DoneAll className={classes.viewAllIcon} /> MARCAR TODAS COMO LEIDAS
              </Button>
            </Row>
          </ListItem>
          {(notifications || []).map((n, i) => notifElement(n, i == notifications.length - 1))}
        </List>
      </Drawer>
      <ConfirmationDialog
        open={!!changelog}
        title={(changelog && changelog.title) || ''}
        message={(changelog && createMarkup(changelog.content)) || ''}
        onOk={() => setChangelog(null)}
        okLabel={'CERRAR'}
        dangerous
      />
    </React.Fragment>
  );
};

Notifications.propTypes = {
  open: any,
  onClose: func,
  history: object
};

export default withRouter(Notifications);
