import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ENABLE_OFFLINE_MODE } from '../utils/globals';

const useMutationOffline = () => {
  const offline = useSelector((state) => state.offline);
  const mutationCall = async (mutation, params) => {
    if (offline) {
      const context = params.context || {};
      if (!context.tracked || !ENABLE_OFFLINE_MODE)
        return Promise.reject({ message: 'OPERACIÓN NO SOPORTADA OFFLINE' });
      mutation(params);
      return Promise.resolve({ data: {} });
    } else {
      return mutation(params);
    }
  };

  const useMutationEF = (mutationGQL, mutParams) => {
    const [mutationFn, data] = useMutation(mutationGQL, mutParams);
    return [(params) => mutationCall(mutationFn, params), data];
  };

  return {
    useMutationEF
  };
};

export default useMutationOffline;
