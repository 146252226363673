import React from 'react';
import { object, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import MainRoutes from './routes/routes.main';
import BarsComponent from './utils/common-components/BarsComponent';
import * as colors from './utils/styles/colors';
import * as media from './utils/styles/media-queries';
import { ENABLE_PWA } from './utils/globals';
import BreadCrumb from './utils/common-components/BreadCrumb';
import Footer from './utils/common-components/Footer';
import * as serviceWorker from './pwa/service-worker';
import customValidationRules from './utils/common-components/forms/CustomValidations';

const mapStateToProps = (state) => {
  return { opesi: state.opesi };
};

const styles = () => ({
  opesiBkg: {
    backgroundColor: colors.lightGray
  },
  innerContainer: {
    paddingLeft: 60,
    minHeight: '80vh',
    ...media.xlScreen({
      paddingLeft: 35
    }),
    ...media.smallScreen({
      paddingLeft: 0,
      minHeight: '57vh'
    })
  }
});

const MainApplication = ({ classes, opesi, location }) => {
  if (ENABLE_PWA) {
    serviceWorker.register();
  } else {
    serviceWorker.unregister();
  }

  customValidationRules();

  const routesWithBkg = [];

  const hasBkg = opesi && routesWithBkg.some((route) => route == location.pathname);

  return (
    <div id="outer-container">
      <BarsComponent location={location} />
      <div id="page-wrap" className={classnames({ [classes.opesiBkg]: hasBkg })}>
        <div id="inner-container" className={classes.innerContainer}>
          <BreadCrumb />
          <MainRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
};

MainApplication.propTypes = {
  location: object,
  classes: object,
  opesi: bool,
  history: object
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(MainApplication);
