import { EnumNotificationType as EnumCoreNotificationType } from '@eeff/shared';

export const TenderStatusEnum = {
  PENDING_ESTIMATE: 'PENDING_ESTIMATE', // Pendiente para cotizar
  ESTIMATED: 'ESTIMATED', //Cotizada,
  NO_ESTIMATED: 'NO_ESTIMATED', // No cotizada
  OPEN: 'OPEN', // apertura
  EVALUATION_REPORT: 'EVALUATION_REPORT', //En dictamen de evaluación
  LOST: 'LOST', // perdida
  PRE_AWARDED: 'PRE_AWARDED', // pre adjudicada
  AWARDED: 'AWARDED', // adjudicada
  PURCHASE_ORDER: 'PURCHASE_ORDER', // Con orden de compra
  PAYMENT_PENDING: 'PAYMENT_PENDING', // pendiente cobro
  PAID: 'PAID', // pagada
  DESSERT: 'DESSERT' // desierta
};

export const EnumRoles = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  DATA_ENTRY: 'DATA_ENTRY'
};

export const EnumEnterprises = {
  H: 'H',
  S: 'S',
  JC: 'JC',
  O: 'O',
  UNS: 'Universidad Nacional del Sur',
  PUERTO_BB: 'Puerto Bahía Blanca',
  MUNICIPALIDAD_BB: 'Municipalidad de Bahía Blanca',
  MUNICIPALIDAD_PA: 'Municipalidad de Punta Alta',
  MILENIO: 'Milenio',
  COOP_OBRERA: 'Cooperativa Obrera',
  BRAYCO: 'Brayco',
  ROGGIO: 'Roggio',
  CARTELLONE: 'Cartellone',
  PAMPA_ENERGIA: 'Pampa Energía',
  AEROTAN: 'Aerotan',
  FERROSUR_ROCA: 'Ferrosur Roca'
};

export const EnumCurrency = {
  ARS: 'ARS',
  EUR: 'EUR',
  USD: 'USD'
};

export function getCurrencySymbol(value) {
  switch (value) {
    case EnumCurrency.ARS:
      return '$';
    case EnumCurrency.USD:
      return 'USD';
    case EnumCurrency.EUR:
      return '€';
    default:
      return '$';
  }
}

export const roleOptions = () => {
  const translateLabel = (label) => {
    switch (label) {
      case 'ADMIN':
        return 'ADMIN';
      case 'MANAGER':
        return 'ENCARGADO';
      case 'DATA_ENTRY':
        return 'EMPLEADO';
    }
  };

  return [
    { label: '', value: undefined },
    ...Object.keys(EnumRoles).map((u) => ({
      label: translateLabel(EnumRoles[u]),
      value: u
    }))
  ];
};

export const EnumConfigKeys = {
  REAL_REVENUE_LIMIT: 'REAL_REVENUE_LIMIT',
  DOLAR_BLUE_MARGIN: 'DOLAR_BLUE_MARGIN'
};

export const EnumNotificationType = {
  ...EnumCoreNotificationType,
  RENEW_OFFER_DATE_ARRIVED: 'RENEW_OFFER_DATE_ARRIVED'
};
