import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import loadingImage from '../../../../public/loading.gif';
import { LOADING_MESSAGE } from '../../globals';
import './Loading.scss';

const Loading = ({
  className,
  imageContainerClassName,
  loading,
  message = 'Cargando...',
  children,
  overlay = true,
  small
}) => {
  const imgSrc = loadingImage;

  return (
    <div
      id="loading-blocker-message"
      className={cx('loadingContainer', className, {
        ['loading']: loading,
        content: children
      })}
    >
      {children && children}
      {loading && (
        <div
          className={cx('loadingImageContainer', imageContainerClassName, {
            overlay: overlay && loading
          })}
        >
          <span className={'text'}>{LOADING_MESSAGE}</span>
          <img className={cx('image', { smallImg: small })} src={imgSrc} alt={message} />
        </div>
      )}
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  imageContainerClassName: PropTypes.string,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  overlay: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.node
};

export default Loading;
