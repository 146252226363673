import React from 'react';
import { Card, CardContent, withStyles } from '@material-ui/core';
import { object, any, string } from 'prop-types';
import classnames from 'classnames';
import * as media from '../../utils/styles/media-queries';

const styles = () => ({
  margin: {
    margin: 'auto',
    ...media.xSmallScreen({
      margin: null
    })
  },
  rootCard: {
    '& > :last-child': {
      paddingBottom: 0
    }
  }
});

class CenteredCard extends React.Component {
  render() {
    const { classes, children, className, ...other } = this.props;
    return (
      <Card
        className={classnames(classes.margin, className)}
        classes={{ root: classes.rootCard }}
        {...other}
      >
        <CardContent>{children}</CardContent>
      </Card>
    );
  }
}

CenteredCard.propTypes = {
  classes: object,
  theme: object,
  children: any,
  className: string
};

export default withStyles(styles, { withTheme: true })(CenteredCard);
