function supportsLocalStorage() {
  const mod = 'modernizr';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

const supportsLS = supportsLocalStorage();
const memoryStorage = {};

export function setItem(name, value) {
  if (supportsLS) {
    window.localStorage.setItem(name, value);
  } else {
    memoryStorage[name] = value;
  }
}

export function getItem(name) {
  if (supportsLS) {
    return window.localStorage.getItem(name);
  } else {
    return memoryStorage[name];
  }
}

export const getTrackedQueries = () =>
  JSON.parse(window.localStorage.getItem('trackedQueries') || null) || [];

export const setTrackedQueries = (queries) => setItem('trackedQueries', queries);
