import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';
import { object } from 'prop-types';
import MainApplication from './MainApplication';
import { setCurrentUrlToRedirectAfterLogin } from './utils/utilities';
import { tryReloadlocalData } from './services/authService';
import KeyDownWrapper from './utils/common-components/KeyDownWrapper';
import { PRIVATE_BASE_PATH, ROUTES_SPECIAL_AUTH } from './utils/globals';

class AppLayout extends React.Component {
  toggleSidebar(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      openedSidebar: !this.state.openedSidebar
    });
  }

  init = async () => {
    /**
     * if the page was refreshed by the user, and the user was already logged in,
     * we need to update the state in our app to reflect that
     */
    setCurrentUrlToRedirectAfterLogin();
    await tryReloadlocalData();

    // put here the routes that need special trait since are used from BE with pupeteer
    if (ROUTES_SPECIAL_AUTH.some((r) => this.props.location.pathname.indexOf(r) !== -1)) {
      const qs = queryString.parse(this.props.location.search);
      if (qs.redirectTo) {
        this.props.history.replace(`${PRIVATE_BASE_PATH}${qs.redirectTo}`);
      }
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <KeyDownWrapper>
        <MainApplication {...this.props} />
      </KeyDownWrapper>
    );
  }
}

AppLayout.propTypes = {
  location: object,
  history: object
};

export default compose(withRouter)(AppLayout);
