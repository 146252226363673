"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.taxTypeAsOptions = exports.getTaxTypeLabel = exports.docTypeAsOptions = exports.clientTaxCategoryAsOptions = exports.EnumTaxType = exports.EnumNotificationType = exports.EnumIdentificationType = exports.EnumClientTaxCategory = void 0;
var EnumClientTaxCategory = {
  CONSUMIDOR_FINAL: 'CONSUMIDOR_FINAL',
  EVENTUAL: 'EVENTUAL',
  EVENTUAL_SOCIAL: 'EVENTUAL_SOCIAL',
  MONOTRIBUTO: 'MONOTRIBUTO',
  MONOTRIBUTO_SOCIAL: 'MONOTRIBUTO_SOCIAL',
  NO_RESPONSABLE: 'NO_RESPONSABLE',
  NO_CATEGORIZADO: 'NO_CATEGORIZADO',
  RESPONSABLE_EXCENTO: 'RESPONSABLE_EXCENTO',
  RESPONSABLE_INSCRIPTO: 'RESPONSABLE_INSCRIPTO'
};
exports.EnumClientTaxCategory = EnumClientTaxCategory;
var clientTaxCategoryAsOptions = Object.keys(EnumClientTaxCategory).map(function (k) {
  return {
    value: k,
    label: EnumClientTaxCategory[k].replace('_', ' ')
  };
});
exports.clientTaxCategoryAsOptions = clientTaxCategoryAsOptions;
var EnumIdentificationType = {
  DNI: 'DNI',
  PASAPORTE: 'PASAPORTE',
  LIBRETA_CIVICA: 'LIBRETA_CIVICA',
  LIBRETA_ENROLAMIENTO: 'LIBRETA_ENROLAMIENTO'
};
exports.EnumIdentificationType = EnumIdentificationType;
var docTypeAsOptions = Object.keys(EnumIdentificationType).map(function (k) {
  return {
    value: k,
    label: EnumIdentificationType[k].replace('_', ' ')
  };
});
exports.docTypeAsOptions = docTypeAsOptions;
var EnumNotificationType = {
  CHANGELOG: 'CHANGELOG'
};
exports.EnumNotificationType = EnumNotificationType;
var EnumTaxType = {
  RET_IIBB: 'RET_IIBB',
  IVA_10_5: 'IVA_10_5',
  IVA_21: 'IVA_21',
  IVA_27: 'IVA_27',
  IVA_0: 'IVA_0'
};
exports.EnumTaxType = EnumTaxType;

var getTaxTypeLabel = function getTaxTypeLabel(taxType) {
  if (!taxType) {
    return null;
  }

  switch (taxType) {
    case EnumTaxType.IVA_10_5:
      return '10.5%';

    case EnumTaxType.IVA_21:
      return '21%';

    case EnumTaxType.IVA_27:
      return '27%';

    case EnumTaxType.RET_IIBB:
      return 'IIBB BS.AS.';

    case EnumTaxType.IVA_0:
      return 'EXCENTO';
  }
};

exports.getTaxTypeLabel = getTaxTypeLabel;
var taxTypeAsOptions = Object.keys(EnumTaxType).map(function (k) {
  return {
    value: k,
    label: getTaxTypeLabel(k)
  };
});
exports.taxTypeAsOptions = taxTypeAsOptions;