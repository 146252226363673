import React, { Component } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { string, object, bool, func, array } from 'prop-types';
import { textFieldStyles } from './inputStyles';

const styles = (theme) => ({
  ...textFieldStyles(theme),
  selectTaxRoot: {
    padding: `10px 10px`
  }
});

/**
 * Default validation rules:
    matchRegexp
    isEmail
    isEmpty
    required
    trim
    isNumber
    isFloat
    isPositive
    minNumber
    maxNumber
    minFloat
    maxFloat
    minStringLength
    maxStringLength
    isString
    maxFileSize
    allowedExtensions
    validators={['minNumber:0', 'maxNumber:255', 'matchRegexp:^[0-9]$']}
 */

/**
 * EXAMPLE HOW TO OVERRIDE THE TEXTFIELD BORDER AN FOCUS BORDER
 * TO replace border color it is only needed to pass notchedOutline class, to change the focus border
 * it is neede to pass the three of them
 * inputClasses={{
    root: classes.cssOutlinedInput,
    focused: classes.cssFocused,
    notchedOutline: classes.inputBorder
  }}

  inputBorder: {
    borderColor: `<color>`
  },
  cssOutlinedInput: {
    '&$cssFocused $inputBorder': {
      borderColor: `<color> !important`
    }
  },

  cssFocused: {}

 */
class TextField extends Component {
  render() {
    const {
      classes,
      className,
      readOnly,
      onEnterKey,
      inputProps,
      InputProps,
      inputClasses,
      validators,
      theme,
      inputLabelClasses,
      containerProps,
      classesProp,
      style,
      ...other
    } = this.props;

    return (
      <TextValidator
        fullWidth
        containerProps={{ style, className }}
        validators={validators}
        classes={classesProp}
        {...other}
        variant="outlined"
        className={classnames(classes.textField)}
        onKeyDown={(e) => {
          if (e.keyCode == 13 && !other.multiline) {
            e.preventDefault();
            e.stopPropagation();
            onEnterKey && onEnterKey();
          }
        }}
        InputLabelProps={{
          required: (validators && validators.indexOf('required') !== -1) || false,
          shrink: true,
          classes: {
            root: classnames(classes.inputLabel, inputLabelClasses)
          }
        }}
        InputProps={{
          readOnly,
          ...InputProps,
          classes: inputClasses
        }}
        inputProps={{
          ...inputProps
        }}
      />
    );
  }
}

TextField.propTypes = {
  classes: object,
  classesProp: object,
  inputClasses: object,
  containerProps: object,
  className: string,
  inputLabelClasses: string,
  id: string,
  readOnly: bool,
  onEnterKey: func,
  inputProps: object,
  InputProps: object,
  theme: object,
  style: object,
  validators: array
};

export default withStyles(styles, { withTheme: true })(TextField);
