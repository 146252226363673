import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lightTheme, darkTheme } from './mui.theme';
import { setThemeMode } from '../../redux/store-actions';
import updateUserGQL from '../../graphql/mutations/profile/updateProfile.graphql';
import useMutationOffline from '../../apollo/useMutationOffline';
import { ENABLE_DARK_THEME } from '../globals';

export const useDarkMode = () => {
  const [theme, setTheme] = useState('light');
  const [componentMounted, setComponentMounted] = useState(false);
  const userProfile = useSelector((state) => state.userProfileData);
  const dispatch = useDispatch();
  const { useMutationEF } = useMutationOffline();
  const [updateProfile, { loading }] = useMutationEF(updateUserGQL);

  const getTheme = (newTheme) => ((newTheme || theme) === 'light' ? lightTheme : darkTheme);

  const toggleTheme = async () => {
    const mode = theme === 'light' ? 'dark' : 'light';
    await updateProfile({
      variables: {
        user: { settings: JSON.stringify({ ...(userProfile.settings || {}), themeMode: mode }) }
      }
    });
    setTheme(mode);
  };

  const getThemeToApply = () => {
    let systemTheme = 'light';
    if (!ENABLE_DARK_THEME) return systemTheme;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      systemTheme = 'dark';
    }

    if (userProfile) {
      if (userProfile.settings && userProfile.settings.themeMode) {
        return userProfile.settings.themeMode;
      }
    }
    return systemTheme;
  };

  useEffect(() => {
    setTheme(getThemeToApply());
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    dispatch(setThemeMode(getThemeToApply()));
  }, [userProfile]);

  useEffect(() => {
    dispatch(setThemeMode(theme));
  }, [theme]);

  return { theme: getTheme(), toggleTheme, getTheme, componentMounted, loading };
};
