import * as React from 'react';
import PropTypes from 'prop-types';
// import { StyledEngineProvider } from '@mui/material/styles'; // TODO MUI V5
import { StylesProvider } from '@material-ui/core/styles';

export default function GlobalCssPriority({ children }) {
  return <StylesProvider injectFirst>{children}</StylesProvider>;
}

GlobalCssPriority.propTypes = {
  children: PropTypes.node
};
