import React from 'react';
import { object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { setSearchFilter, clearSearchFilter } from '../../redux/store-actions';
import store from '../../redux/store';
import SearchBarComponent from './SearchbarComponent';

const mapStateToProps = (state) => {
  return { searchFilter: state.searchFilter };
};

const styles = (theme) => ({
  search: {
    'position': 'relative',
    'borderRadius': theme.shape.borderRadius,
    'backgroundColor': alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    'marginRight': theme.spacing(2),
    'marginLeft': 0,
    'width': '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  }
});

class GlobalDebouncedFilterComponent extends React.Component {
  constructor() {
    super();
    this.onFilterChange = debounce(this.onFilterChange, 700 || 0);
    this.state = { value: '' };
  }

  onChange = (value) => this.setState({ value }, () => this.onFilterChange(value));

  onFilterChange = () => {
    const filter = (this.state.value || '').trim();
    if (!filter) {
      return store.dispatch(clearSearchFilter());
    }
    if (filter.length >= 3) {
      return store.dispatch(setSearchFilter(filter));
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    /**
     * This part it is utilized to clear the filter when the page change. In the moment that the component
     * with the searchbar enable is unmounted, it has to clear the store, in that moment we listen to that
     * change and we need to clear the input's field value
     */
    if (
      nextProps &&
      !nextProps.searchFilter &&
      nextProps.searchFilter !== this.props.searchFilter
    ) {
      this.setState({ value: nextProps.searchFilter || '' });
    }
  }

  render() {
    return (
      <SearchBarComponent
        id="header-filter-field"
        onChange={(e) => this.onChange(e.target.value)}
        value={this.state.value}
        style={this.props.style}
        className={this.props.className}
      />
    );
  }
}

GlobalDebouncedFilterComponent.propTypes = {
  classes: object,
  children: object,
  location: object,
  history: object,
  searchFilter: string,
  className: string,
  style: object
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(GlobalDebouncedFilterComponent);
