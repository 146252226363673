import { createBrowserHistory } from 'history';

const myHistory = createBrowserHistory();

export const USER_TYPE = process.env.USER_TYPE || 'ADMIN';
export const ENABLE_PWA = process.env.ENABLE_PWA || true;
export const APP_ENV = process.env.APP_ENV || 'local';

// this line allows us to have the history object available everywhere in the project
// when you are outside a component, like in the authService or utilities file
export const browserHistory = myHistory;
export const DRAWER_WITH = 195;
export const API_URL = process.env.API_URL;
export const PRIVATE_BASE_PATH = '/sistema/';
export const LOADING_MESSAGE = 'Cargando...';
export const VERSION = '2.0.0';
export const APOLLO_CACHE_KEY = 'apollo-cache-licitaciones';
export const AUTH_TOKEN_KEY = 'auth-token-licitaciones';
export const FOOTER_PRIVACY_TERMS_URL = 'someurl';

export const ROUTES_WITH_SEARCHBAR = [];
export const ROUTES_SPECIAL_AUTH = ['fetchfile'];

export const POLLING_INTERVAL = 360000; // 6min
export const POLLING_PROFILE_INTERVAL = 120000; // 2min
export const ENABLE_OFFLINE_MODE = false;
export const ENABLE_DARK_THEME = false;
export const ENABLE_NOTIFICATIONS = true;

export const COMPANY_PHONE = '(XXX) XXX-XXXX';
export const COMPANY_NAME = 'change';
export const COMPANY_FULLNAME = 'Hier Logistics';
export const COMPANY_ADDRESS = 'Paso 237';
export const SOFT_NAME = 'TenderLy';

export const MERCADOPAGO_PUBLIC_KEY = process.env.MERCADOPAGO_PUBLIC_KEY;
export const RECAPTCHA_V3_SITE_KEY = process.env.RECAPTCHA_V3_SITE_KEY;

console.log(`<<<<< Starting ${USER_TYPE} >>>>>`);
