import React, { Component } from 'react';
import { bool, object, func, string, any } from 'prop-types';
import {
  Dialog,
  DialogContent,
  withMobileDialog,
  Button,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { isXSmallScreen } from '../styles/media-queries';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary.light
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15)
    }
  }
});

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, onOk, onCancel, message, title, okLabel, dangerous, fullScreen, useFullScreen } =
      this.props;
    return (
      <Dialog
        PaperProps={{ style: { padding: 15 } }}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCancel();
          }
        }}
        disableEscapeKeyDown
        fullScreen={fullScreen && useFullScreen}
        maxWidth={isXSmallScreen() && useFullScreen ? null : 'sm'}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">{title || ''}</DialogTitle>
        <DialogContent>
          {dangerous && message ? (
            <div dangerouslySetInnerHTML={message} />
          ) : (
            <div style={{ fontSize: 16 }}>{message}</div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          {onCancel && (
            <Button variant="contained" size="medium" onClick={onCancel} color="secondary">
              NO
            </Button>
          )}
          {onOk && (
            <Button
              variant="contained"
              size="medium"
              onClick={onOk}
              color="primary"
              id="confirmation-dialog-ok"
            >
              {okLabel || 'SI'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  classes: object,
  className: string,
  message: any,
  dangerous: bool,
  open: bool,
  fullScreen: bool,
  useFullScreen: bool,
  onOk: func,
  onCancel: func,
  title: string,
  okLabel: string
};

export default withStyles(styles, { withTheme: true })(withMobileDialog()(ConfirmationDialog));
