export const xxSmallScreenWidth = 375;
export const xSmallScreenWidth = 479;
export const smallScreenWidth = 768;
export const mediumScreenWidth = 968;
export const xMediumScreenWidth = 1024;
export const newLargeScreenWidth = 1366;
export const largeScreenWidth = 1440;

// beaware when you use this queries - it might match more than one
export const isXxSmallScreen = () => window.innerWidth <= xxSmallScreenWidth;
export const isXSmallScreen = () => window.innerWidth <= xSmallScreenWidth;
export const isSmallScreen = () => window.innerWidth <= smallScreenWidth;
export const isXMediumScreen = () => window.innerWidth <= mediumScreenWidth;
export const isMediumScreen = () => window.innerWidth <= xMediumScreenWidth;
export const isLargeScreen = () => window.innerWidth <= largeScreenWidth;
export const isExtraLargeScreen = () => window.innerWidth >= largeScreenWidth;
export const isAtLeastSmallScreen = () => window.innerWidth > smallScreenWidth;
export const isAtLeastMediumScreen = () => window.innerWidth >= mediumScreenWidth;
export const isAtLeastXMediumScreen = () => window.innerWidth >= xMediumScreenWidth;

export const isAtLeastWidth = (width) => window.innerWidth >= width;
export const isAtMostWidth = (width) => window.innerWidth <= width;

export const isAtLeastHeight = (height) => window.innerHeight >= height;
export const isAtMostHeight = (height) => window.innerHeight <= height;

// Only max-width so they can stack -- use in this order
export const xxSmallScreen = (obj) => ({
  '@media (max-width: 375px)': obj
});

// Only max-width so they can stack -- use in this order
export const xSmallScreen = (obj) => ({
  '@media (max-width: 479px)': obj
});

// Only max-widht so they can stack -- use in this order
export const smallScreen = (obj) => ({
  '@media screen and (max-width: 768px)': obj
});

export const print = (obj) => ({
  '@media print': obj
});

export const mediumScreen = (obj) => ({
  '@media (min-width: 1024px)': obj
  // '@media (min-width: 769px, max-width: 968px)': obj
});

export const largeScreen = (obj) => ({
  '@media (min-width: 1366px)': obj
});

export const tillLargeScreen = (obj) => ({
  '@media (max-width: 1300px)': obj
});

export const xlScreen = (obj) => ({
  '@media (min-width: 1441px)': obj
});

export const hideOnPrint = {
  '@media print': {
    display: 'none'
  }
};

// export const page = (obj) => ({
//   '@page': obj
// });

export const showOnPrint = {
  'display': 'none',
  '@media print': {
    display: 'block'
  }
};

export const hideXSmall = {
  hideXSmall: xSmallScreen({
    display: 'none'
  })
};

export const hideSmall = {
  hideSmall: smallScreen({
    display: 'none'
  })
};

export const hideLarge = {
  hideLarge: mediumScreen({
    display: 'none'
  })
};

export const hideUpToLarge = {
  hideUpToLarge: tillLargeScreen({
    display: 'none'
  })
};

export const ie10Up = (obj) => ({
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': obj
});

export const mediaMinWidth = (width, obj) => {
  const item = {};
  item[`@media (min-width: ${width}px)`] = obj;
  return item;
};

export const mediaMaxWidth = (width, obj) => {
  const item = {};
  item[`@media (max-width: ${width}px)`] = obj;
  return item;
};
