const errorMessages = {
  401: 'ACCESO DENEGADO',
  402: 'CUENTA SUSPENDIDA',
  600: 'USUARIO NO ENCONTRADO',
  601: 'NÚMERO DE DOCUMENTO DUPLICADO',
  602: 'NÚMERO DE CUIT DUPLICADO'
};

export const getErrorMessage = (err) => {
  const graphQLErrors = err.graphQLErrors;
  const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
  return errorMessages[e.code] || e.message || err.message || 'HA OCURRIDO UN ERROR';
};

export const getErrorCode = (err) => {
  if (!err) return;
  const graphQLErrors = err.graphQLErrors;
  const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
  return e.code;
};
