import { browserHistory, AUTH_TOKEN_KEY } from '../utils/globals';
import { setCurrentUrlToRedirectAfterLogin } from '../utils/utilities';
import * as localStorageService from './localStorageService';
import { updateUserProfileAction, clearUserProfileAction } from '../redux/store-actions';
import store from '../redux/store';
import { queryAndUpdateUserData, clearLocalCache } from '../apollo/apollo-utils';
import { EnumRoles } from '../enums/Enums';

export const getAuthKey = () => {
  return AUTH_TOKEN_KEY;
};

export const getCurrentToken = () => localStorageService.getItem(getAuthKey());

export function isLoggedIn() {
  return !!localStorageService.getItem(AUTH_TOKEN_KEY);
}

export function login({ token, userProfile }) {
  token && localStorageService.setItem(getAuthKey(), token);
  if (userProfile) {
    store.dispatch(updateUserProfileAction(userProfile));
  }
}

/**
 * If the user refreshed the page and him was already logged in
 * we need to re login him and update the redux store to reflect that state
 */
export const tryLoginFromLocalStorage = () => login({ token: getCurrentToken() });

/**
 * If the user refreshed the page and him was already logged in
 * we need to retrieve the user profile again and update the redux store to reflect that state
 */
export const tryReloadlocalData = async () => {
  await clearLocalCache();

  if (isLoggedIn()) {
    const user = await queryAndUpdateUserData();
    login({ userProfile: user });
  }

  return Promise.resolve();
};

export function alternateLogin(token) {
  token && localStorageService.setItem(getAuthKey(), token);
}

/**
 * Just resets the login token saved in the local storage
 * @param redirectBackAfterLogin
 */
export function lightLogout(redirectBackAfterLogin = true) {
  const loginRoute = '/login';
  store.dispatch(clearUserProfileAction());

  if (browserHistory.location.pathname !== loginRoute) {
    if (redirectBackAfterLogin) {
      setCurrentUrlToRedirectAfterLogin();
    }

    browserHistory.push(loginRoute);
  }
}

/**
 * Clear the auth token in LocalStorage, resets the redux store
 * and clears the Apollo store to flush its cache
 * @param {*} redirectBackAfterLogin
 */
export function logout(redirectBackAfterLogin = true) {
  lightLogout(redirectBackAfterLogin);
  localStorageService.setItem(getAuthKey(), '');
  setTimeout(() => {
    clearLocalCache();
  }, 300);
}

export const isAdmin = () => {
  const { userProfileData } = store.getState();
  const roles = userProfileData?.roles || [];
  return roles.indexOf(EnumRoles.ADMIN) !== -1;
};

export const isUserWorkshop = () => {
  const { userProfileData } = store.getState();
  const roles = userProfileData?.roles || [];
  return roles.indexOf(EnumRoles.WORKSHOP) !== -1;
};
