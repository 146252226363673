import React, { lazy } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
const TenderRoutes = lazy(() => import('./routes.tenders'));
const DashboardComponent = lazy(() => import('../pages/DashboardContainer'));
const ProfileContainer = lazy(() => import('../pages/ProfileComponent'));
const UserAdministration = lazy(() => import('../pages/UserAdministration'));
const CommissionsReport = lazy(() => import('../pages/tenders/TendersCommissionsReport'));
const SettingsComponent = lazy(() => import('../pages/settings/SettingsComponent'));
import { useIsAdmin } from '../hooks/useIsAdmin';

const AppRoutes = (props) => {
  const me = props.match.url;
  const isAdmin = useIsAdmin();

  return (
    <Switch>
      {isAdmin && <Route exact path={`${me}users`} component={UserAdministration} />}
      {isAdmin && <Route exact path={`${me}commissionsreport`} component={CommissionsReport} />}
      <Route exact path={`${me}dashboard`} component={DashboardComponent} />
      <Route exact path={`${me}profile`} component={ProfileContainer} />
      {isAdmin && <Route exact path={`${me}settings`} component={SettingsComponent} />}
      <Route path={`${me}tenders`} component={TenderRoutes} />
    </Switch>
  );
};

AppRoutes.propTypes = {
  match: PropTypes.object
};

export default AppRoutes;
