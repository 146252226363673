import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from '../utils/common-components/PageNotFound';
import { PRIVATE_BASE_PATH } from '../utils/globals';
import LoginComponent from '../pages/LoginComponent';
import AppRoutes from './routes.app';
import PrivateRouteValidator from './PrivateRouteValidator';
import LogoutComponent from '../utils/common-components/LogoutComponent';
import ErrorPage from '../utils/common-components/ErrorPage';
import { Loading } from '../utils/common-components/UIBlocker';

export default class MainRoutes extends React.Component {
  render() {
    return (
      <Suspense fallback={<Loading loading />}>
        <Switch>
          <Route path="/login" component={LoginComponent} />
          <Route path="/logout" component={LogoutComponent} />
          <Route path="/error" component={ErrorPage} />
          <Redirect exact from="/" to={`${PRIVATE_BASE_PATH}dashboard`} />
          <PrivateRouteValidator path={PRIVATE_BASE_PATH} component={AppRoutes} />
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    );
  }
}

/**
 * NOTES FOR USE
 * Please, do not add any Suspense tag inside the route files, with this one in the root it works. If JIC you add one,
 * add it as a root element, not inside the switch tag since it brokes the routing
 */
