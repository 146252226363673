import React, { useState } from 'react';
import { bool, object, func } from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { Row, Column } from 'simple-flexbox';
import { withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import Loading from '../utils/common-components/UIBlocker/Loading';
import TextField from '../utils/common-components/forms/TextField';
import * as authService from '../services/authService';
import { getUrlToRedirectAfterLogin, setUrlToRedirectAfterLogin } from '../utils/utilities';
import { PRIVATE_BASE_PATH, USER_TYPE } from '../utils/globals';
import CenteredCard from '../utils/common-components/CenteredCardComponent';
import { showErrorMessage } from '../utils/common-components/ToastNotificationEmitter';
import { getErrorMessage } from '../utils/errors';
import loginGQL from '../graphql/mutations/profile/login.graphql';

const styles = () => ({
  title: {
    marginBottom: 30
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    width: 200
  }
});

const LoginComponent = ({ history, classes }) => {
  const redirectTo = history?.location?.state?.redirectTo;

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const [login, { loading }] = useMutation(loginGQL);

  const onSubmit = async () => {
    try {
      const { data } = await login({
        variables: {
          user: user.toLowerCase(),
          password,
          type: USER_TYPE
        }
      });

      const response = data?.login || {};
      if (response?.result.success) {
        authService.login({ token: response.result.token, userProfile: response.profile });
        if (redirectTo) {
          return history.push(redirectTo);
        }
        const url = getUrlToRedirectAfterLogin();
        setUrlToRedirectAfterLogin('');

        // console.log({ url, redirectTo, response });
        if (url) {
          return history.push(url);
        }
        return history.push(`${PRIVATE_BASE_PATH}dashboard`);
      } else {
        showErrorMessage('Inicio Sesión Inválido');
      }
    } catch (e) {
      console.log(e);
      showErrorMessage(getErrorMessage(e));
    }
  };
  return (
    <Loading loading={loading} style={{ marginTop: 50 }}>
      <CenteredCard style={{ width: 300, paddingBottom: 24 }} raised>
        <Column>
          <Typography variant={'h4'} className={classes.title}>
            Iniciar Sesión
          </Typography>
          <ValidatorForm onSubmit={onSubmit} onError={(errors) => console.log(errors)}>
            <Column vertical="center">
              <TextField
                id="user"
                name="user"
                value={user}
                label={'Usuario'}
                autoFocus
                style={{ marginBottom: 30 }}
                validators={['required']}
                errorMessages={['Obligatorio']}
                onChange={(e) => setUser(e.target.value)}
                autoCapitalize="none"
                autoComplete="new-password"
              />
              <TextField
                id="password"
                type="password"
                value={password}
                name="password"
                label={'Contraseña'}
                style={{ marginBottom: 40 }}
                validators={['required']}
                errorMessages={['Obligatorio']}
                onChange={(e) => setPassword(e.target.value)}
                onEnterKey={onSubmit}
                autoCapitalize="none"
              />
              <Row horizontal="center" vertical="center">
                <Button
                  id="login-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size={'large'}
                  classes={{ root: classes.button }}
                >
                  Ingresar
                </Button>
              </Row>
            </Column>
          </ValidatorForm>
        </Column>
      </CenteredCard>
    </Loading>
  );
};

LoginComponent.propTypes = {
  loading: bool,
  classes: object,
  history: object,
  login: func,
  theme: object
};

export default withStyles(styles, { withTheme: true })(LoginComponent);
