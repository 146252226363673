import { createTheme } from '@material-ui/core/styles';
import * as colors from './colors';

const commonProps = {
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 3,
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: `${colors.lightThemePalette.primary.main} !important`
        }
      },
      adornedStart: {
        paddingLeft: 6
      }
    },
    PrivateNotchedOutline: {
      legendLabelled: {
        paddingRight: 35 // TODO investigar como hacerlo en base al span width
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 3
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: 4
      },
      root: {
        padding: 8,
        fontSize: 16
      }
    }
    // MuiTableRow: {
    //   root: {
    //     '& > :last-child': {
    //       borderRight: '1px solid grey'
    //     }
    //   }
    // }
    // MuiTableBody: {
    //   '& tr:last-child': {
    //     borderBottom: '1px solid grey'
    //   }
    // }
  }
};
const getLightTheme = () => {
  const { overrides, ...rest } = commonProps;
  return {
    palette: {
      primary: colors.lightThemePalette.primary,
      secondary: colors.lightThemePalette.secondary,
      error: {
        main: colors.red
      },
      background: {
        default: '#f4f6f9'
      }
    },
    overrides: {
      ...overrides,
      MuiPaper: {
        root: {
          backgroundColor: '#fafafa'
        }
      }
    },
    ...rest
  };
};

const getDarkTheme = () => {
  const { overrides, ...rest } = commonProps;
  return {
    palette: {
      type: 'dark',
      secondary: colors.darkThemePalette.secondary,
      primary: colors.darkThemePalette.primary,
      error: {
        main: '#ff6e40'
      },
      background: {
        default: '#222222'
      },
      text: {
        primary: '#f4f6f9'
      }
    },
    overrides: {
      ...overrides,
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#2e7d32'
          }
        }
      },
      MuiExpansionPanel: {
        root: {
          backgroundColor: '#757575'
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: '#757575'
        }
      }
    },
    ...rest
  };
};

export const lightTheme = createTheme(getLightTheme());
export const darkTheme = createTheme(getDarkTheme());
