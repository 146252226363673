// import { createStore } from "redux";
// import rootReducer from "./store-reducers";
// const store = createStore(rootReducer);
// export default store;

import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './store-reducers';

const persistConfig = {
  key: 'app-root',
  storage,
  whitelist: ['trackedQueries']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

export const persistor = persistStore(store);
