import React from 'react';
import keydown from 'react-keydown';
import { any, object } from 'prop-types';
// import { IS_ADMIN } from '../globals';
// import { toggleOpesiFilter } from '../../redux/store-actions';
// import store from '../../redux/store';

/**
 * IF YOU WANT TO ADD SHORTCUTS ONLY FOR ONE PAGE USE THE WRAPPER LIKE THIS AND PROCESS THE EVENT LIKE IN THIS
 * WRAPPER
 * 
 * const CreateSellComponent = (props) => (
  <KeyDownWrapper>
    <CreateSellComponentWrapped {...props} />
  </KeyDownWrapper>
);
 */
@keydown
class KeyDownWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      keydown: null
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.keydown !== this.props.keydown) {
      // this.processKeyDownEvent(nextProps.keydown && nextProps.keydown.event);
      this.setState(
        { keydown: (nextProps.keydown && nextProps.keydown.event) || null },
        this.processKeyDownEvent
      );
    }
  }

  // ADD HERE GLOBAL SHORTCUTS
  handleAltY = () => {
    // do some action with the store if you want
    console.log;
  };

  processKeyDownEvent = () => {
    const event = this.state.keydown;
    if (!event) return;
    /*ctrlKey (aka control)
        shiftKey
        altKey (aka option)
        metaKey (aka command or cmd)
        */
    if (event.altKey && event.keyCode == 89) {
      return this.handleAltY();
    }
  };

  render() {
    const { children, keydown, ...rest } = this.props;
    return React.cloneElement(children, { keydown: (keydown && keydown.event) || null, ...rest });
  }
}

KeyDownWrapper.propTypes = {
  children: any,
  keydown: object
};

export default KeyDownWrapper;
